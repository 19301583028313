import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Fuel controller connected")
  }

  calculate(e) {
    console.log("calculate")
    let l_per_km = document.getElementById("l_per_km")
    let km = document.getElementById("km")
    let l = document.getElementById("liter")
    let price = document.getElementById("price_per_l")
    let left_litre = document.getElementById("left_litre")
    let to_left_litre = document.getElementById("to_left_litre")
    console.log("l_per_km", l_per_km.value)
    console.log("km", km.value)
    let total =  0
    let liters = 0
    if(l_per_km.value && km.value){
      liters = (to_left_litre.value - left_litre.value) + ((l_per_km.value * km.value) / 100)
      l.value = liters
    }
    if(price.value && l.value){
      total = price.value * l.value
    }
    l.value = liters.toFixed(2)
    document.getElementById("price").value = total.toFixed(2)
  }
}
