import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.setMainTermsElements();
  }

  setMainTermsElements(){
    let main_terms = document.getElementById("main_terms");
    let data = JSON.parse(main_terms.dataset.content);
    let general_terms = document.getElementById("general_terms");
    let general_data = JSON.parse(general_terms.dataset.content);
    this.setInnerElements(data, main_terms, "main_terms");
    this.setInnerElements(general_data, general_terms, "general_terms");
  }

  setInnerElements(data, main_element, name){
    if(data){
      Object.keys(data).forEach((column) => {
        main_element.insertAdjacentHTML('beforeend', this.columnHtmlContent(data[column]["id"], name, data[column]));
      })
    }
  }

  addColumn(e){
    let element = e.target;
    let type = element.dataset.type;
    let terms = document.getElementById(type);
    let columns = terms.querySelectorAll(".column");
    let size = 1;
    if(columns.length > 0){
      let last_column = columns[columns.length - 1];
      let position = last_column.dataset.position;
      size = parseInt(position) + 1
    }
    terms.insertAdjacentHTML('beforeend', this.columnHtmlContent(size, type));
  }

  removeColumn(e){
    let element = e.target;
    let id = element.dataset.id;
    let column = document.getElementById(`column_${id}`);
    column.remove();
  }

  columnHtmlContent(id, name, content={id:"", title: "", conditions: ""}){
    return `
      <div class="col-12 column" id="column_${name}_${id}" data-position="${id}">
        <input name="corporate_leasing_term[${name}][column_${id}][id]" value="${id}" type="hidden">
        <div class="form-group">
            <label>Väliotsikko</label>
            <input class="form-control" name="corporate_leasing_term[${name}][column_${id}][title]" type="text" value="${content.title}">
        </div>
        <div class="form-group">
            <label>Ehdon sisältö</label>
            <textarea class="form-control" name="corporate_leasing_term[${name}][column_${id}][conditions]" rows="10">${content.conditions}</textarea>
        </div>
        <div class="w-100 text-right">
            <button class="btn btn-danger btn-sm" data-action="click->terms#removeColumn" data-id="${name}_${id}">Poista</button>
        </div>
        <hr />
      </div>
    `
  }
}