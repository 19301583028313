import { Controller } from "stimulus"
import $ from "jquery";
export default class extends Controller {
  connect() {
    // console.log("Hello, Stimulus! from import_controller.js")
  }

  toggleFixingInputs(e) {
    // data-id
    const import_id = e.currentTarget.dataset.id
    //  value of this radio button
    const value = e.currentTarget.value
    console.log(`import_id: ${import_id}, value: ${value}`)
    if (value === 'true') {
      $(`#prepare_sale_fixing_done_cb_${import_id}`).prop('disabled', false)
      $(`#prepare_sale_fixing_done_date_${import_id}`).prop('disabled', false)
      $(`#prepare_sale_fixing_done_bg_${import_id}`).removeClass('bg-light')
    } else {
      $(`#prepare_sale_fixing_done_cb_${import_id}`).prop('disabled', true)
      $(`#prepare_sale_fixing_done_date_${import_id}`).prop('disabled', true)
      $(`#prepare_sale_fixing_done_bg_${import_id}`).addClass('bg-light')
    }
  }

  // when checkbox is checked the close date is set to today
  markDone(e) {
    const done_date = $(e.currentTarget).closest('.input-group').find('.done_date')
    if (e.currentTarget.checked) {
      const today = new Date().toISOString().split('T')[0]
      done_date.val(today)
    } else {
      done_date.val('')
    }
  }

  selectItem(e) {
    const selected_ids = $('input[name="selected_ids"]:checked')
    const vin_containers = $('.vin_container')
    vin_containers.empty()

    let vins = []
    selected_ids.each(function() {
      vins.push($(this).val())
      // add vin to vin_containers
      vin_containers.append(`<div class="bg-info text-white rounded p-1 m-1" style="font-size: 0.7em">${$(this).val()}</div>`)
    })
    if (selected_ids.length > 0) {
      $('.check-box-tools').removeClass('d-none')
    } else {
      $('.check-box-tools').addClass('d-none')
    }
    $('#selected_amount').text(selected_ids.length)
  }

  deleteSelected(e) {
    const selected_ids = $('input[name="selected_ids"]:checked')
    if (!confirm(`Oletko varma poistaa ${selected_ids.length} tilaus?`)) {
      return
    }
    selected_ids.each(function() {
      const vin = $(this).val()
      const id = $(this).data('id')
      const url = `/import_tracks/${id}`
      $.ajax({
        url: url,
        type: 'DELETE',
        dataType: 'json',
        success: function(result) {
          console.log("remove success", result)
          iziToast.success({message: `Vin ${vin} poistettu`, position: 'topRight'})
          $(`#import_track_${id}`).remove()
          $(`#import_track_form_${id}`).remove()
        },
        error: function(result) {
          console.log("remove error", result)
          iziToast.error({message: `Vin ${vin} poistaminen epäonnistui`, position: 'topRight'})
        }
      })
    })
  }

  editStatusSelected(e) {
    // e is radio button get value
    const statusValue = e.currentTarget.value
    const selected_ids = $('input[name="selected_ids"]:checked')
    let isFinished = false
    selected_ids.each(function() {
      const vin = $(this).val()
      const id = $(this).data('id')
      const url = `/import_tracks/${id}`
      $.ajax({
        url: url,
        type: 'PATCH',
        data: {import_track: {status: statusValue}},
        dataType: 'json',
        success: function(result) {
          console.log("editStatusSelected success", result)
          iziToast.success({message: `Vin ${vin} muutettu`, position: 'topRight'})
          isFinished = true
        },
        error: function(result) {
          console.log("editStatusSelected error", result)
          iziToast.error({message: `Vin ${vin} muuttaminen epäonnistui`, position: 'topRight'})
        }
      })
    })
    setTimeout(function() {
      if (isFinished) {
        reloadTracks()
      }
      $('.modal').modal('hide')
    }, 2000)
  }

  saveSelected(e) {
    // get input inside div name = .inputs which not have style="display: none;"
    const input_divs = $(e.currentTarget).closest('.modal').find('.inputs').not('[style="display: none;"]')
    // get all inputs type date inside input_divs
    const inputs = input_divs.find('input')
    let input_data = {}

    inputs.each(function() {
      if ($(this).attr('name')) {
        input_data[$(this).attr('name')] = $(this).val()
      }
    })
    const patch_data = {import_track: input_data}
    console.log("patch_data", patch_data)
    const selected_ids = $('input[name="selected_ids"]:checked')
    let isFinished = false
    selected_ids.each(function() {
      const vin = $(this).val()
      const id = $(this).data('id')
      const url = `/import_tracks/${id}`
      $.ajax({
        url: url,
        type: 'PATCH',
        data: patch_data,
        dataType: 'json',
        success: function(result) {
          console.log("saveSelected success", result)
          iziToast.success({message: `Vin ${vin} muutettu`, position: 'topRight'})
          isFinished = true
        },
        error: function(result) {
          console.log("saveSelected error", result)
          iziToast.error({message: `Vin ${vin} muuttaminen epäonnistui`, position: 'topRight'})
        }
      })
    })

    setTimeout(function() {
      if (isFinished) {
        reloadTracks()
      }
      $('.modal').modal('hide')
    }, 3000)
  }

  clearSelected(e) {
    $('input[name="selected_ids"]').prop('checked', false)
    $('.vin_container').empty()
    $('#selected_amount').text(0)
    $('.check-box-tools').addClass('d-none')
  }
}

function reloadTracks() {
  const status = $('#status').val()
  const keyword = $('#keyword').val()
  $(`#import_tracks_container`).load('/import_tracks/reload_import_tracks?status=' + status + '&keyword=' + keyword)
  $('#selected_amount').text(0)
  $('.check-box-tools').addClass('d-none')
  $('.vin_container').empty()
}
