import { Controller } from "stimulus"

export default class extends Controller {

  copyInfo() {
    let fields = ["address", "postcode", "city", "phone", "email"]
    fields.forEach((field) => {
      let el = document.getElementById(`privateleasing_contract_customer_info_${field}`)
      let el2 = document.getElementById(`privateleasing_contract_customer2_${field}`)
      el2.value = el.value;
    })
  }
}
