import { Controller } from "stimulus";

export default class extends Controller {
    connect(){
        let agreement_type = document.getElementById("corporate_leasing_contract_contract_info_agreement_type");
        if(agreement_type && agreement_type.value !== "Kone- ja Laiterahoitusleasing"){
            this.resetSellersInfo();
        }
    }

    updateSellersInfo(e){
        let element = e.target;
        let value = element.value;
        if(value === "Kone- ja Laiterahoitusleasing"){
            this.resetSellersInfo("show");
        }else {
            this.resetSellersInfo("hide");
        }
    }

    resetSellersInfo(action="hide"){
        let seller_store = document.getElementById("sales_store");
        if(action === "show"){
            seller_store.classList.remove("d-none");
        }else {
            seller_store.classList.add("d-none");
            let inputs = seller_store.querySelectorAll("input");
            inputs.forEach(input => {
                input.value = "";
            });
        }
    }
}