import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

    static targets = ["imginside", "imgoutside", "removeInfo", "imgsales"];

    current_side = "inside";
    parent_id = "";
    x_coord_val = "";
    y_coord_val = "";
    is_removing = false;


    connect() {
    }

    showImage(e) {
        let element = e.target;
        let url = element.dataset.dotUrl;
        let id = element.id;

        if(this.is_removing){
            let controller = this;
            let deleted_btn = document.querySelector(".button_to");
            //deleted_btn.action = `/inspection_galleries/${id}`;
            //deleted_btn.submit();

            Rails.ajax({
                url: `/inspection_galleries/${id}`,
                type: 'post',
                data: new FormData(deleted_btn),
                success: function (response) {
                    let {status} = response;
                    if(status === 'ok'){
                        let removed_dot = document.getElementById(id);
                        removed_dot.remove();
                        controller.removeInfoTarget.textContent = "";
                        controller.is_removing = false;
                        controller.updateDotImageSection(controller.current_side, "");
                    }
                },
                error: function (response) {
                    alert("Virhe tiedoston poisto");
                },
            })
        }else {
            this.updateDotImageSection(this.current_side, url);
        }
    }

    updateDotImageSection(side, url){
        if (side === 'inside'){
            this.imginsideTarget.src = url;
        }

        if (side === 'outside'){
            this.imgoutsideTarget.src = url;
        }

        if (side === 'sales'){
            this.imgsalesTarget.src = url;
        }
    }

    removeRedDots(){
        let dots_container = document.querySelectorAll('.blueprint-dot');
        dots_container.forEach(d => {
            if(d.classList.contains('bg-danger')){
                d.remove();
            }
        });
    }

    addDotPosition(e){
        this.removeRedDots();
        let element = e.target;
        let temp = element.dataset.modelTemplate;
        let dot_id = element.dataset.dotId;
        let position = this.getPosition(e);
        let x_coord = ~~position.x;
        let y_coord = ~~position.y;
        let temp_dot = this.createTemporaryDot(x_coord, y_coord);
        let container = document.getElementById(`blueprint-container-${temp}`);
        container.innerHTML += temp_dot;

        this.parent_id = dot_id;
        this.x_coord_val = x_coord;
        this.y_coord_val = y_coord;

        let file_image = document.getElementById("file_image");
        file_image.click();
    }

    fileUploaded(){
        let controller = this;
        let [file] = file_image.files;
        let temp_dot_id = `temp-id-${this.x_coord_val}-${this.y_coord_val}`;
        let dot = document.getElementById(temp_dot_id);

        if(!file){
            // Remove dot
            dot.remove();
            alert("Sopiva Tiedoston ei ole");
            return;
        }
        if (file.size > 2500000) {
            // Remove dot
            dot.remove();
            alert("Tiedoston koko on enintään 2,5 MB.");
        } else {
            let form_data = new FormData();
            form_data.append('file', file);
            form_data.append('parent_id', this.parent_id);
            form_data.append('x', this.x_coord_val);
            form_data.append('y', this.y_coord_val);
            form_data.append('is_gallery', 'true');
            form_data.append('is_request_url', 'true');

            Rails.ajax({
                url: "/inspections/upload_image_attachment",
                type: "POST",
                data: form_data,
                success: function (response) {
                    controller.updateDotImageSection(controller.current_side, response);
                    dot.classList.remove('bg-danger');
                    dot.classList.add('bg-success');
                    dot.dataset.dotUrl = response;
                },
                error: function (response) {
                    alert("Sopiva tiedostoa ei ole");
                }
            })
        }
    }

    removeDot(){
        this.is_removing = true;
        if(this.is_removing){
            this.removeInfoTarget.textContent = "Valmis poistamaan. Klikkaa piste, jonka haluat poistaa."
        }
    }

    updateCurrentSide(e){
        let element = e.target;
        this.current_side = element.dataset.side;
    }

    getPosition(e) {
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        return {
            x,
            y
        }
    }

    createTemporaryDot(x, y) {
        return `<div class="position-absolute blueprint-dot bg-danger rounded-circle" data-action="click->inspection-gallery#showImage" id="temp-id-${x}-${y}" style="left: ${x}px; top: ${y}px;"></div>`
    }
}
