import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = []
    current_path_name = null;
    connect() {
        this.current_path_name = window.location.pathname;
        this.updateCarInfoFromUrl();
    }

    searchCustomer(e) {
        let icon = document.getElementById('search_icon');
        icon.classList.remove('fa-search')
        icon.classList.add('fa-spinner', 'fa-spin');
        let value = e.target.value;
        let container = document.getElementById('customer_search_results');
        let containers = document.querySelectorAll("[id='customer_search_results']")
        if (containers.length > 1) {
            console.log("containers", containers.length);
            container = e.target.parentNode.querySelector('#customer_search_results');
        }

        if (value.length > 0) {
            Rails.ajax({
                type: 'get',
                url: "/leasing_order_agreements/get_customer",
                data: `keyword=${value}`,
                success: (response) => {
                    if (response && response.length > 0) {
                        let html = '';
                        response.forEach((customer) => {
                            html += `
                            <li data-action="click->customer-car-search#updateCustomerInfo"
                            data-customer-info='${JSON.stringify(customer)}'
                            class="py-1">${customer.ssn} - ${customer.name} - ${customer.email} - ${customer.city}</li>
                            <hr class="m-0">
                            `;
                        });
                        container.innerHTML = html;
                        icon.classList.remove('fa-spinner', 'fa-spin');
                        icon.classList.add('fa-search');
                    } else {
                        container.innerHTML = `<li class="py-1">Ei tuloksia</li>`;
                        icon.classList.remove('fa-spinner', 'fa-spin');
                        icon.classList.add('fa-search');
                    }
                    container.classList.remove('d-none');
                },
                error: (response) => {
                    console.log("error");
                }
            })
        } else {
            this.hideSearchInputAndResults(container);
            icon.classList.remove('fa-spinner', 'fa-spin');
            icon.classList.add('fa-search');
        }
    }

    startCustomerSearch(){
        let input = document.getElementById('customer_search_input');
        this.searchCustomer({target: input});
    }

    updateCustomerInfo(e){
        let element = e.target;
        let customerInfo = JSON.parse(element.dataset.customerInfo);
        let section = this.current_path_name.includes("leasing_order_agreements") ? "leasing_order_agreement_customer_info" : "leasing_service_agreement_customer_info";

        if (this.current_path_name.includes("brand_order_agreement")){
            let searchInput = element.parentNode.parentNode.querySelector('.customer_search');
            const formId =  searchInput.dataset.customer
            console.log("formId", formId);
            section = "brand_order_agreement_" + formId;
        }

        let inputs = ["ssn","name","email","phone","postcode","address","city"]
        if(this.current_path_name.includes("corporate_leasing_offers")){
            section = "corporate_leasing_offer_company";
            inputs = ["y_id", "name", "email","phone","postcode","address","city", "contact_person_name", "contact_person_email", "contact_person_phone"]
            customerInfo["y_id"] = customerInfo["ssn"]
            customerInfo["contact_person_name"] = ""
            customerInfo["contact_person_email"] = customerInfo["email"]
            customerInfo["contact_person_phone"] = customerInfo["phone"]
        }
        inputs.forEach((input) => {
            let inputElement = document.getElementById(`${section}_${input}`);
            let value = customerInfo[input];
            if(inputElement){
                inputElement.value = value;
            }
        });
        let container = document.getElementById('customer_search_results');
        let containers = document.querySelectorAll("[id='customer_search_results']")
        if (containers.length > 1){
            console.log("containers", containers.length);
            container = e.target.parentNode;
        }
        this.hideSearchInputAndResults(container);
    }

    updateCarInfoFromUrl(){
        // get query params from url
        let urlParams = new URLSearchParams(window.location.search);
        let carRegNum = urlParams.get('reg_num');
        console.log(carRegNum);
        if(carRegNum){
            let controller = this;
            Rails.ajax({
                type: 'get',
                url: "/leasing_order_agreements/get_car",
                data: `reg_num=${carRegNum}`,
                success: (response) => {
                    if(response && response.length > 0){
                        let car = JSON.stringify(response[0]);
                        controller.updateCarInfoFields(response[0]);
                    }
                },
                error: (response) => {
                    console.log("error");
                }
            })
        }
    }

    searchCar(e){
        let value = e.target.value;
        let container = document.getElementById('car_search_results');
        if(value.length > 0){
            Rails.ajax({
                type: 'get',
                url: "/leasing_order_agreements/get_car",
                data: `reg_num=${value}`,
                success: (response) => {
                    if(response && response.length > 0){
                        let html = '';
                        response.forEach((car) => {
                            html += `
                            <li data-action="click->customer-car-search#updateCarInfo"
                            data-car-info='${JSON.stringify(car)}'
                            class="py-1">${car.reg_num} - ${car.make} - ${car.model}</li>
                            <hr class="m-0">
                            `;
                        });
                        container.innerHTML = html;
                    }else {
                        container.innerHTML = `<li class="py-1">Ei tuloksia</li>`;
                    }
                    container.classList.remove('d-none');
                },
                error: (response) => {
                    console.log("error");
                }
            })
        }else {
            this.hideSearchInputAndResults(container);
        }
    }

    updateCarInfo(e){
        let element = e.target;
        let carInfo = JSON.parse(element.dataset.carInfo);
        this.updateCarInfoFields(carInfo);
    }

    updateCarInfoFields(carInfo){
        let section = this.current_path_name.includes("leasing_order_agreements") ? "leasing_order_agreement_vehicle_info" : "leasing_service_agreement_vehicle_info"
        let inputs = ["reg_num","make","model","vehicle_type","vin","mileage","tax"]
        inputs.forEach((input) => {
            let inputElement = document.getElementById(`${section}_${input}`);
            let value = null;
            if(input === "tax"){
                value = carInfo["annual_tax"]
            }else if (input === "vehicle_type"){
                value = carInfo["base_type"]
            }else {
                value = carInfo[input];
            }

            if(value){
                inputElement.value = value;
            }
        });
        let list = document.getElementById('car_search_results');
        this.hideSearchInputAndResults(list);
    }

    hideSearchInputAndResults(element){
        element.classList.add('d-none');
        let searchInput = element.previousElementSibling;
        searchInput.value = "";
    }
}
