import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
    console.log("Start free_drive_controller")
    let from = document.getElementById("pickup_office_1")
    let to = document.getElementById("delivery_office_1")
    if (from && to) {
      from = from.value
      to = to.value
      let thisDistance = officesDistance(from, to)
      if (!thisDistance) {
        thisDistance = 0
      }
      thisDistance = (thisDistance / 1000).toFixed(2)
      let elementDistance = document.getElementById("distance")
      elementDistance.value = thisDistance
    }
  }



  saveFreeDrive(e) {
    const thisButton = e.target
    thisButton.disabled = true
    thisButton.innerText = 'Lataa...Odotta hetki'
    console.log("Start saveFreeDrive")

    const url = `/extra_notes/save_free_drive`
    let free_drive_data = {
      "free_drive_task": {
        "pickup_office": $('#pickup_office_1').val(),
        "pickup_address": $('#pickup1_address').val(),
        "pickup_postcode": $('#pickup1_zip').val(),
        "pickup_location": $('#pickup1_city').val(),
        "pickup_time_start": $('#pickup_date_from').val(),
        "pickup_time_end": $('#pickup_date_from').val(),
        "pickup_contact": {
          "name": $('#pickup_contact_name').val(),
          "phone": $('#pickup_contact_phone').val(),
          "email": $('#pickup_contact_email').val()
        },
        "deliver_office": $('#delivery_office_1').val(),
        "deliver_address": $('#deliver1_address').val(),
        "deliver_postcode": $('#deliver1_zip').val(),
        "deliver_location": $('#deliver1_city').val(),
        "deliver_time_start": $('#deliver_date_to').val(),
        "deliver_time_end": $('#deliver_date_to').val(),
        "deliver_contact": {
          "name": $('#deliver_contact_name').val(),
          "phone": $('#deliver_contact_phone').val(),
          "email": $('#deliver_contact_email').val()
        },
        "driver_license_class": $('#driver_license_class').val(),
        "car_license_plate": $('#free_drive_car_license_plate').val(),
        "car_make": $('#free_drive_car_make').val(),
        "car_model": $('#free_drive_car_model').val(),
        "car_year": $('#free_drive_car_year').val(),
        "car_color": $('#free_drive_car_color').val(),
        "car_fuel": $('#free_drive_car_fuel').val(),
        "car_gear": $('#free_drive_car_gear').val(),
        "car_fuel_estimate": $('#free_drive_fuel_estimate').val(),
        "car_fuel_price": $('#free_drive_fuel_price').val(),
        "car_fuel_consumption": $('#free_drive_car_fuel_consumption').val(),
        "more_info": $('#free_drive_more_info').val(),
        "note_for_driver": $('#free_drive_note_for_driver').val()

      }
    }
    const free_drive_id = document.getElementById("free_drive_id").value
    // const form = document.getElementById("free_drive_form")
    if (!free_drive_id) {
      console.log("free_drive_id not found save new")
    } else {
      console.log("free_drive_id found save update")
      free_drive_data["id"] = free_drive_id
    }

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: free_drive_data,
      success: (data) => {
        console.log("data 111", data.result.status)
        if (data.result.status === "SUCCESS") {
          if (!free_drive_id) {
            $('#free_drive_form').addClass('d-none')
            $('#save_free_drive').addClass('d-none')
            $('#free_drive_modal_footer').addClass('d-none')
            $('#free_drive_result').html("Ilmainen siirto tallennettu onnistuneesti.")
            $('#new_close_button').removeClass('d-none')
          }
          iziToast.success({message: `Ilmainen siirto tallennettu onnistuneesti.`, position: 'topRight'})
          thisButton.disabled = false
          thisButton.innerText = 'Tallenna'
        } else {
          $('#free_drive_result').html("Tallennus epäonnistui, yritä uudelleen.")
          iziToast.error({message: `Tallennus epäonnistui, yritä uudelleen.`, position: 'topRight'})
          thisButton.disabled = false
          thisButton.innerText = 'Tallenna'
        }
      }
    });
  }

  reloadPage(e) {
    location.reload()
  }
  searchCar(e) {
    const reg_element = document.getElementsByName("car_license_plate")[0]
    // reg_element not exist or less that 7 characters
    if (!reg_element || reg_element.value.length < 7) {
      return
    }
    const reg_num = reg_element.value.toUpperCase()
    reg_element.value = reg_num
    //  available_cars=in_stock&keyword=123
    const url = `/cars?available_cars=in_stock&keyword=${reg_num}`
    // cal with type javscript json
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        console.log("data result", data)
        if (data.length > 0) {
          const car = data[0]
          console.log("data result car", car)
          document.getElementsByName("car_make")[0].value = car.make
          document.getElementsByName("car_model")[0].value = car.model
          document.getElementsByName("car_year")[0].value = car.year
          document.getElementsByName("car_gear")[0].value = car.gear
          document.getElementsByName("car_fuel")[0].value = car.fuel
          document.getElementsByName("car_color")[0].value = car.color
          document.getElementsByName("car_fuel_consumption")[0].value = car.fuel_consumption_combined
          // cal calculateFuelPrice
          this.calculateFuelPrice()
        }
      }
    });
  }

  getDistance(e) {
    let from = document.getElementById("pickup_office_1").value
    let to = document.getElementById("delivery_office_1").value



    let thisDistance = officesDistance(from, to)
    if (!thisDistance) {
      thisDistance = 0
    }
    thisDistance = (thisDistance / 1000).toFixed(2)
    let elementDistance = document.getElementById("distance")
    elementDistance.value = thisDistance
    //   Jos haluat matkustaa oulu-vantaa BMW X5:llä automaattivaihteistolla, valitse tämä ajoneuvo.
    //   <textarea class="form-control" name="more_info" rows="3"></textarea>
    let more_info = document.getElementById("free_drive_more_info")
    if (thisDistance > 0 && more_info) {
      // let make_model_text = ""
      // if (document.getElementsByName("car_make")[0]) {
      //   make_model_text = `${document.getElementsByName("car_make")[0].value} ${document.getElementsByName("car_model")[0].value}:llä`
      // }
      // let gear_text = ""
      // if (document.getElementsByName("car_gear")[0]) {
      //   gear_text = `${document.getElementsByName("car_gear")[0].value}vahteistolla`
      // }
      // let info_text = `Jos haluat matkustaa ${from}-${to} ${make_model_text} ${gear_text}, valitse tämä ajoneuvo.`
      // const pickup_date_from = document.getElementsByName("pickup_date_from")[0].value
      // console.log("pickup_date_from", pickup_date_from)
      // // pickup_date_from
      // // pickup_date_from 2024-02-28T09:00
      // if (pickup_date_from) {
      //   // add text book this drive before 2 days from pickup_date_from
      //   let pickup_date = new Date(pickup_date_from)
      //   console.log("pickup_date", pickup_date)
      //   pickup_date.setDate(pickup_date.getDate() - 2)
      //   console.log("pickup_date", pickup_date)
      //   const dd = String(pickup_date.getDate()).padStart(2, '0');
      //   const mm = String(pickup_date.getMonth() + 1).padStart(2, '0'); //January is 0!
      //   const yyyy = pickup_date.getFullYear();
      //   info_text += ` Muista varata tämä ajoneuvo viimeistään ${dd}.${mm}.${yyyy}.`
      // }

      let info_text = "Auton nouto liikkeen aukioloaikojen puitteissa"
      more_info.value = info_text

      this.calculateFuelPrice()
    }

    //   Start set address to pickup and delivery hidden fields
    const offices = {}
    offices['kuopio'] = {address: 'Kartanonkatu 4 A', postcode: '70700', location: 'Kuopio'}
    offices['oulu'] = {address: 'Konetie 4', postcode: '90630', location: 'Oulu'}
    offices['raisio'] = {address: 'Allastie 1', postcode: '21200', location: 'Raisio'}
    offices['tuusula'] = {address: 'Sulantie 10', postcode: '04300', location: 'Tuusula'}
    offices['vantaa'] = {address: 'Martinkyläntie 45', postcode: '01720', location: 'Vantaa'}
    offices['muu'] = {address: '', postcode: '', location: ''}
    if (offices[from]) {
      $('#pickup1_address').val(offices[from].address)
      $('#pickup1_zip').val(offices[from].postcode)
      $('#pickup1_city').val(offices[from].location)
    }
    if (offices[to]) {
      $('#deliver1_address').val(offices[to].address)
      $('#deliver1_zip').val(offices[to].postcode)
      $('#deliver1_city').val(offices[to].location)
    }
  }

  calculateFuelPrice() {
    console.log("Start calculateFuelPrice")
    const km = document.getElementById("distance")
    const l_per_100_km = document.getElementById("free_drive_car_fuel_consumption")
    const fuel = document.getElementById("free_drive_car_fuel")
    // if not exist or empty
    if (!km || !l_per_100_km || !fuel) {
      return
    }


    // label#free_drive_fuel_95.text-primary style="font-size: 10px"   = @fuel_price["95".to_sym]
    const price_95 = $("#free_drive_fuel_95").text()
    const price_de = $("#free_drive_fuel_de").text()
    // console.log("fuel.value", fuel.value, "price_95", price_95, "price_de", price_de)

    let price_per_l = 0
    if ($('#free_drive_fuel_price')?.val() > 0) {
      price_per_l = $('#free_drive_fuel_price').val()
    } else if (fuel.value === "Diesel/Hybridi" || fuel.value === "Diesel") {
      price_per_l = price_de
      $('#free_drive_fuel_price').val(price_de)
    } else if (fuel.value === "Bensa/Hybridi" || fuel.value === "Bensiini" || fuel.value === "E85/Bensiini") {
      price_per_l = price_95
      $('#free_drive_fuel_price').val(price_95)
    }
    if (price_per_l === 0) {
      return
    }

    let price = 0
    console.log("km", km.value, "l_per_100_km", l_per_100_km.value, "price_per_l", price_per_l, "fuel", fuel.value)
    if (km.value && km.value > 0 && l_per_100_km.value ) {
      price = (km.value * l_per_100_km.value / 100) * price_per_l
    }
    // console.log("END price", price)
    document.getElementById("free_drive_fuel_estimate").value = price.toFixed(2)
  }


}

function officesDistance(from, to) {
  const OFFICE_DISTANCES = {
    "kuopio_oulu": 296210,
    "kuopio_raisio": 447217,
    "kuopio_tuusula": 362674,
    "kuopio_vantaa": 385671,
    "oulu_kuopio": 296317,
    "oulu_raisio": 650140,
    "oulu_tuusula": 588589,
    "oulu_vantaa": 611586,
    "raisio_kuopio": 447798,
    "raisio_oulu": 650174,
    "raisio_tuusula": 182776,
    "raisio_vantaa": 161382,
    "tuusula_kuopio": 363085,
    "tuusula_oulu": 588223,
    "tuusula_raisio": 181695,
    "tuusula_vantaa": 21826,
    "vantaa_kuopio": 386210,
    "vantaa_oulu": 611349,
    "vantaa_raisio": 161263,
    "vantaa_tuusula": 22686
  };
  return OFFICE_DISTANCES[`${from}_${to}`]
}
