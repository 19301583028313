// exchange_car_inspection_controller.js
import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
    console.log("exchange_car_inspection_controller.js connected")
  }

  officeChanged(e) {
    const office = e.target.value
    const email_contacts = {
      raisio: "luca.giacoletto@autolle.com",
      oulu: "sakarias.simuna@autolle.com",
      tuusula: "eepi.tuononen@autolle.com",
      kuopio: "mikael.mannerkorpi@autolle.com",
      vantaa: "andreas.haltunen@autolle.com"
    }

    const chief_options = $('#exchange_car_inspection_approved_by_chief_email option')
    for (let i = 0; i < chief_options.length; i++) {
      if (chief_options[i].value == email_contacts[office]) {
        $('#exchange_car_inspection_approved_by_chief_email').val(email_contacts[office]).trigger('change');
        return
      }
    }
  }
}
