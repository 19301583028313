import { Controller } from "stimulus"

export default class extends Controller {
    LEASE_KMS = {
        "12": {
            "15": 25,
            "20": 50,
            "25": 75,
            "30": 100,
        },
        "24": {
            "15": 25,
            "20": 50,
            "25": 75,
            "30": 100,
        },
        "36": {
            "15": 25,
            "20": 50,
            "25": 75,
            "30": 100,
        },
        "48": {
            "15": 25,
            "20": 50,
            "25": 75,
            "30": 100,
        },
        "60": {
            "15": 25,
            "20": 50,
            "25": 75,
            "30": 100,
        }
    }
  leasing_data = [];
  leasing_km_data = {};
  connect() {
      this.showTable();
  }

  showTable(){
      let leasing_input = document.getElementById('leasing');
      if(leasing_input){
          let leasing_input_value = leasing_input.value;
          if(leasing_input_value){
              this.leasing_data = JSON.parse(leasing_input_value)
              this.drawTable(this.leasing_data)
              this.drawLeaseKmTable(this.leasing_data);
          }
      }
  }

  updateLeasingData(e){
      let element = e.target;
      if(element){
          let name = element.name
          let val = element.value
          let index = parseInt(element.dataset.index, 10)
          let leasing_input = document.getElementById('leasing');
          if(name === 'status'){
              if(element.checked){
                  val = '1'
              }else {
                  val = '0'
              }
          }

          if(name === 'price' || name === 'months' || name === 'km'){
            this.leasing_data[index][name] = parseInt(val, 10);
          }else {
              this.leasing_data[index][name] = val;
          }
          leasing_input.value = JSON.stringify(this.leasing_data);

          if(name === 'price' || name === 'status'){
              this.updateCurrentMonthlyPrice();
          }
      }
  }

  updateCurrentMonthlyPrice(){
      let monthly_price = document.getElementById('leasing_monthly_price');
      let monthly_price_visible = document.getElementById('leasing_monthly_price_visible');
      let active_price = this.leasing_data.filter(ls => ls["status"] === '1');
      if(active_price.length > 0){
          monthly_price.value = active_price[0]['price']
          monthly_price_visible.value = active_price[0]['price']
      }
  }

  drawTable(leasing){
      let table = document.getElementById('leasing-details-table')
      let table_body = table.getElementsByTagName('tbody')[0]
      let rows = ""
      leasing.forEach((l, index) => {
          let row = `<tr>
                        <td class="p-0 pt-2 text-center" style="height: 0; vertical-align: top;"><button data-action="leasing-details#removeRow" data-index="${index}" class="btn bg-transparent border-0 p-0"><i class="far fa-times-circle text-danger" style="font-size: 18px; pointer-events: none;"></i></button></td>
                        <td class="p-0 pt-2" style="height: 0; vertical-align: top;"><input data-action="leasing-details#updateLeasingData" name="status" data-index="${index}" class="w-100" type="checkbox" ${l['status'] === '1' && 'checked'}></td>
                        <td class="p-0" style="height: 0; vertical-align: top;"><input data-action="leasing-details#updateLeasingData" name="months" data-index="${index}" class="w-100 border-0 p-1" type="number" value="${l['months']}"></td>
                        <td class="p-0" style="height: 0; vertical-align: top;"><input data-action="leasing-details#updateLeasingData" name="price" data-index="${index}" class="w-100 border-0 p-1" type="number" value="${l['price']}"></td>
                        <td class="p-0" style="height: 0; vertical-align: top;"><input data-action="leasing-details#updateLeasingData" name="km" data-index="${index}" class="w-100 border-0 p-1" type="number" value="${l['km']}"></td>
                        <td class="p-0" colspan="2" style="height: 0; vertical-align: top;"><textarea data-action="leasing-details#updateLeasingData" name="details" data-index="${index}" class="w-100 border-0 p-1" rows="4">${l['details']}</textarea></td>
                    </tr>`;
          rows += row;
      });
      table_body.innerHTML = rows;
  }

  removeRow(e){
    let element = e.target;
    if(element){
        let leasing_input = document.getElementById('leasing');
        let index = parseInt(element.dataset.index, 10)
        this.leasing_data.splice(index, 1)
        this.drawTable(this.leasing_data)
        leasing_input.value = JSON.stringify(this.leasing_data);
    }
  }

  addRow(){
    let row_data = {"km": 10, "price": 0, "months": 12, "status": "0", "details": ""}
    let leasing_input = document.getElementById('leasing');
    this.leasing_data.push(row_data)
    this.drawTable(this.leasing_data);
    leasing_input.value = JSON.stringify(this.leasing_data);
  }

    showKmOptionsTable(e){
      let elem = e.target;
      let container = document.getElementById("km-options-table-container");
      let leasing_km_options_input = document.getElementById("leasing_km_options");
        if(container.classList.contains('d-none')){
            container.classList.remove('d-none');
            elem.innerHTML = `<span class="mr-2 pe-none">Poista Vakio KM Hinta</span> <span class="pe-none"><i class="fas fa-close"></i></span>`
            elem.classList.add('btn-danger');
            elem.classList.remove('btn-primary');
            leasing_km_options_input.value = JSON.stringify(this.leasing_km_data);
        }else {
            container.classList.add('d-none');
            elem.innerHTML = `<span class="mr-2 pe-none">Muttaa Vakio KM Hinta</span> <span class="pe-none"><i class="fas fa-close"></i></span>`
            elem.classList.add('btn-primary');
            elem.classList.remove('btn-danger');
            leasing_km_options_input.value = null;
        }
    }

    drawLeaseKmTable(leasing_data){
        let table = document.getElementById('leasing-km-options-table')
        if(table){
            let table_body = table.getElementsByTagName('tbody')[0]
            let rows = ""
            let months = leasing_data.map(lease => lease['months'])
            months.forEach((month, index) => {
                let row = this.leaseKmTableRow(month);
                rows += row;
            });
            table_body.innerHTML = rows;
        }
    }

    leaseKmTableRow(month){
      return `
        <tr class="border border-primary">
            <td>${month}</td>
            <td>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style="width: 100px;">Sallittu KM / Vuosi (tkm)</th>
                            <th style="width: 100px;">Hinta (€)</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${this.leaseKmPricePerRow(month)}
                    </tbody>
                </table>
            </td>
        </tr>
      `
    }

    leaseKmPricePerRow(month){
      let lease_input = document.getElementById('leasing_km_options');
      let lease_data = JSON.parse(lease_input.value)
        if(!lease_data) {
            lease_data = this.LEASE_KMS;
        }
        this.leasing_km_data[month] = lease_data[month];
      let row = ""
        let km_options = [15, 20, 25, 30];
        km_options.forEach((km, index) => {
            row += `<tr>
                     <td>${km}</td>
                     <td class="p-0"><input data-action="leasing-details#updateLeasingKmData" name="${month}" data-km="${km}" class="w-100 border-0 p-1 h-100" type="number" value="${this.leasing_km_data[`${month}`][`${km}`]}"></td>
                    </tr>`
      })

        return row
    }

    updateLeasingKmData(e){
      let element = e.target;
      let month = element.name;
      let km = element.dataset.km;
      let value = parseInt(element.value, 10);
      this.leasing_km_data[month][km] = isNaN(value) ? 0 : value;
        let lease_input = document.getElementById('leasing_km_options');
        lease_input.value = JSON.stringify(this.leasing_km_data);
    }
}
