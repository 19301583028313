import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
      // console.log("Hello, Search Stimulus!")
  }

  searchChange(event) {
    let search = event.target.value
    // console.log("Search value: ", search)
    // Remove existing search-item
    $('#result-container .search-item').remove();
    $('#result-container-car .search-item').remove()
    $('#result-container-ins .search-item').remove()
    $('#result-container-exi .search-item').remove()
    if (search.length < 3) {
      return;
    }
    // Create 5 new search-item
    // for(let i = 0; i < 5; i++) {
    //     $('#result-container').append('<div class="search-item"><a href="#">x hakutulos</a></div>');
    // }
    //     /search_result?keyword=test
    $.ajax({
      url: '/search_result',
      type: 'GET',
      data: {keyword: search},
      dataType: 'json',
      success: function(data) {
        console.log("Search keyword: ", search, "result", data)
        // Varastossa search-header car  result-container-car
        if (data.cars && data.cars.length > 0) {
          $('.search-header.car').removeAttr('hidden');
          $('#result-container-car .search-item').remove()
          data.cars.slice(0, 10).forEach(function(car) {
            const status_fi = car.status === 'in_stock' ? 'Myynnissä' : 'Poistettu'
            $('#result-container-car').append('<div class="search-item"><a href="/cars/' + car.id + '">' + `${car.reg_num} ${car.make} ${car.model} [${status_fi}]` + '</a></div>');
          });
        } else {
          $('.search-header.car').attr('hidden', true);
          $('#result-container-car .search-item').remove()
        }

        // inspections result-container-ins
        if (data.inspections && data.inspections.length > 0) {
          $('.search-header.ins').removeAttr('hidden');
          $('#result-container-ins .search-item').remove()
          data.inspections.slice(0, 10).forEach(function(inspection) {
            $('#result-container-ins').append('<div class="search-item"><a href="/inspections?keyword=' + inspection.plate + '">' + `${inspection.plate} ${inspection.phase_name}` + '</a></div>');
          });
        } else {
          $('.search-header.ins').attr('hidden', true);
          $('#result-container-ins .search-item').remove()
        }

        // Vaihtoautot Tarkastuslomakkeet result-container-exi
        if (data.exchange_inspections && data.exchange_inspections.length > 0) {
          $('.search-header.exi').removeAttr('hidden');
          $('#result-container-exi .search-item').remove()
          data.exchange_inspections.slice(0, 10).forEach(function(exchange_inspection) {
            $('#result-container-exi').append('<div class="search-item"><a href="/exchange_car_inspections?keyword=' + exchange_inspection.reg_num + '">' + `${exchange_inspection.reg_num} ${exchange_inspection.make_model}` + '</a></div>');
          });
        } else {
          $('.search-header.exi').attr('hidden', true);
          $('#result-container-exi .search-item').remove()
        }

        // Vaihtoautojen myyntiinlaittoprosessi  exchange_car_tracks ext
        if (data.exchange_car_tracks && data.exchange_car_tracks.length > 0) {
          $('.search-header.ext').removeAttr('hidden');
          $('#result-container-ext .search-item').remove()
          data.exchange_car_tracks.slice(0, 10).forEach(function(exchange_car_track) {
            let link_text = `${exchange_car_track.plate} ${exchange_car_track.office}`
            if (exchange_car_track.seller) {
              link_text += ` ${exchange_car_track.seller}`
            }
            $('#result-container-ext').append('<div class="search-item"><a href="/exchange_car_tracks?office=' + exchange_car_track.office + '&keyword=' + exchange_car_track.plate + '">' + `${link_text}` + '</a></div>');
          });
        } else {
          $('.search-header.ext').attr('hidden', true);
          $('#result-container-ext .search-item').remove()
        }
      }
    });
  }
}