import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
    console.log("RV data controller connected")
    let this_connect = this
    $(document).on("change","#rv_make", function(e){
      let element = e.target
      this_connect.changeModel(element)
      this_connect.changeVariant(element)
    });
    $(document).on("change","#rv_model", function(e){
      let element = e.target
      this_connect.changeVariant(element)
    });
  }

  changeModel(element) {
    console.log("change models for ", element.value)
    let make =  element.value;
    let model_element = $("#rv_model");
    model_element.find('option').each(function() {
      if ( make == "Merkki") {
        $(this).prop('disabled', false);
      } else if ($(this).attr('data-make') != make) {
        $(this).prop('disabled', true);
      } else {
        $(this).prop('disabled', false);
      }
    })
  }
  changeVariant(element) {
    console.log("change Variant", element.value)
    let model =  element.value;
    let variant_element = $("#rv_variant");
    variant_element.find('option').each(function() {
      if ( model == "Malli") {
        $(this).prop('disabled', false);
      } else if ($(this).attr('data-model') != model) {
        $(this).prop('disabled', true);
      } else {
        $(this).prop('disabled', false);
      }
    });
  }
}
