import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('👍 make-datalist, Stimulus!');
  }

  changeModels(event) {
    const inputMake = event.target;
    const make = inputMake.value;
    console.log("make: ", make);
    const modelList = document.getElementById('model_list');
    const models = modelList.querySelectorAll('option');
    models.forEach((model) => {
      if (model.dataset.make === make) {
        model.style.display = "block";
        console.log("model: ", model.value, "is ----------------------visible", model.dataset.make);
      } else {
        model.style.display = "none";
        console.log("model: ", model.value, "is hidden", model.dataset.make);
      }
    });

  }

}
