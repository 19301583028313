import { Controller } from "stimulus"

export default class extends Controller {

    inputNames = {
        "company-name": "selected_company",
        "fuel_type": "selected_fuel_type",
        "period": "selected_period",
        "yearly": "selected_years",
    }

    connect() {
        console.log('connected ', this)
    }

    selectAll(e){
        e.preventDefault();
        let elem = e.target;
        let name = elem.dataset.name;
        this.updateSelectedAllValues(name);
    }

    updateSelectedAllValues(name){
        let inputs = document.querySelectorAll(`.${name}`);
        let selected_company_data = []
        let selected_fuel_type_data = []
        Array.from(inputs).forEach(input => {
            input.checked = 'checked'
            if(name === 'company-name'){
                selected_company_data.push(input.value)
            }

            if(name === 'fuel-type'){
                selected_fuel_type_data.push(input.value)
            }
        })

        console.log('selected company ', selected_company_data)

        if(selected_company_data.length > 0){
            let selected_company = document.getElementById('selected_company')
            selected_company.value = selected_company_data.join(";")
        }

        if(selected_fuel_type_data.length > 0){
            let selected_fuel_type = document.getElementById('selected_fuel_type')
            selected_fuel_type.value = selected_fuel_type_data.join(";")
        }
    }

    removeAll(e){
        e.preventDefault();
        let elem = e.target;
        let name = elem.dataset.name
        let inputs = document.querySelectorAll(`.${name}`);
        Array.from(inputs).forEach(input => {
            input.checked = ''
        })

        if(name === 'company-name'){
            document.getElementById('selected_company').value = ""
        }

        if(name === 'fuel-type'){
            document.getElementById('selected_fuel_type').value = ""
        }
    }

    searchCompanyName(e){
        let val = e.target.value;
        console.log('val ', val);
        let containers = document.querySelectorAll(".company-inputs-container");
        Array.from(containers).forEach(container => {
            let input = container.getElementsByTagName('input')[0]
            let input_value = input.value;
            if(val.length > 0){
                if(input_value.toLowerCase().startsWith(val.toLowerCase())){
                    container.classList.remove('d-none')
                }else {
                    container.classList.add('d-none')
                }
            }else {
                container.classList.remove('d-none')
            }
        })
    }

    selectByCheckbox(e){
        let elem = e.target;
        let name = elem.dataset.name
        let selected_input = document.getElementById(this.inputNames[name]);
        if(selected_input){
            let selected_input_value = selected_input.value;
            let selected_input_value_data = selected_input_value ? selected_input_value.split(";") : []
            if(elem.checked){
                selected_input_value_data.push(elem.value)
            }else {
                selected_input_value_data = selected_input_value_data.filter(val => val !== elem.value)
            }
            selected_input.value = [...new Set(selected_input_value_data)].join(";")
        }
    }

    selectByPeriod(e){
        let elem = e.target;
        let period_inputs = document.querySelectorAll('.period-inputs');
        let selected_period = document.getElementById('selected_period');
        Array.from(period_inputs).forEach(input => {
            if(input.value === elem.value){
                selected_period.value = elem.value;
                input.checked = 'checked';
            }else {
                input.checked = '';
            }
        })
    }
}
