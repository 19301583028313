import "@fortawesome/fontawesome-free/css/all.css";
import $ from 'jquery';
import 'select2'
import 'select2/dist/css/select2.css'
import 'jquery-scrollto'
import 'jqdoublescroll'

$(document).on("turbolinks:load", function() {
  // ChartJS
  if(window.Chart) {
    Chart.defaults.global.defaultFontFamily = "'Nunito', 'Segoe UI', 'Arial'";
    Chart.defaults.global.defaultFontSize = 12;
    Chart.defaults.global.defaultFontStyle = 500;
    Chart.defaults.global.defaultFontColor = "#999";
    Chart.defaults.global.tooltips.backgroundColor = "#000";
    Chart.defaults.global.tooltips.bodyFontColor = "rgba(255,255,255,.7)";
    Chart.defaults.global.tooltips.titleMarginBottom = 10;
    Chart.defaults.global.tooltips.titleFontSize = 14;
    Chart.defaults.global.tooltips.titleFontFamily = "'Nunito', 'Segoe UI', 'Arial'";
    Chart.defaults.global.tooltips.titleFontColor = '#fff';
    Chart.defaults.global.tooltips.xPadding = 15;
    Chart.defaults.global.tooltips.yPadding = 15;
    Chart.defaults.global.tooltips.displayColors = false;
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.mode = 'nearest';
  }

  // DropzoneJS
  if(window.Dropzone) {
    Dropzone.autoDiscover = false;

    // console.log("DropzoneJS is loaded and ready to use");
    let dropzoneInstances = Dropzone.instances;
    let myDropzone;

    for(let i = 0; i < dropzoneInstances.length; i++) {
      if(dropzoneInstances[i].element.id === "dropzoneInventory") { // replace "myDropzoneElementId" with your actual id
        myDropzone = dropzoneInstances[i];
        break;
      }
      if(dropzoneInstances[i].element.id === "dropzoneInventoryMultiple") { // replace "myDropzoneElementId" with your actual id
        myDropzone = dropzoneInstances[i];
        // console.log("Found the Dropzone instance");
        break;
      }
    }

    if(myDropzone) {
      let successfulUploads = 0;
      let failedUploads = 0;
      let duplicateUploads = 0;

      myDropzone.on("complete", function(file) {
        console.log("Complete: ", file);
        if (file.status === Dropzone.SUCCESS) {
          successfulUploads++;
          console.log("Successful uploads: ", successfulUploads)
        } else if (file.status === Dropzone.ERROR) {
          if (file.xhr.status === 422) {
            duplicateUploads++;
            console.log("Duplicate uploads: ", duplicateUploads)
          } else {
            failedUploads++;
            console.log("Failed uploads: ", failedUploads)
          }
        }
      });
      // console.log("Found the Dropzone instance");
      myDropzone.on("queuecomplete", function() {
        if (failedUploads > 0 || duplicateUploads > 0) {
          let message = ``
          if (successfulUploads > 0) {
            message = `${successfulUploads} kuvaa lisätty. `
          }
          if (duplicateUploads > 0) {
            message += ` ${duplicateUploads} kuvaa lisätty, mutta rekisterikilpi on jo olemassa. `
          }
          if (failedUploads > 0) {
            message += ` ${failedUploads} kuvaa epäonnistui, Kuvassa ei löytynyt rekisterikilpeä. Ole hyvä ja zoomaa lähemmäs rekisterikilpeä.`
          }
          if (failedUploads > 0) {
            iziToast.error({
              message: message,
              title: 'Virhe',
              position: "center"
            });
          } else {
            iziToast.info({
              message: message,
              title: 'Duplikaatti',
              position: "center"
            });
          }
        } else {
          iziToast.success({
            message: `${successfulUploads} kuvaa lisätty onnistuneesti` ,
            title: 'Onnistui',
            position: "center"
          });
        }

        successfulUploads = 0;
        failedUploads = 0;
        duplicateUploads = 0;

        // wait 2 seconds before reloading the page to allow the user to see the success message
        setTimeout(function() {
          location.reload();
        }, 2000);
      });
    } else {
      console.log("Dropzone instance not found");
    }
  }

  // Basic confirm box
  // $('[data-confirm]').each(function() {
  //   var me = $(this),
  //       me_data = me.data('confirm');
  //
  //   me_data = me_data.split("|");
  //   me.fireModal({
  //     title: me_data[0],
  //     body: me_data[1],
  //     buttons: [
  //       {
  //         text: me.data('confirm-text-yes') || 'Yes',
  //         class: 'btn btn-danger btn-shadow',
  //         handler: function() {
  //           eval(me.data('confirm-yes'));
  //         }
  //       },
  //       {
  //         text: me.data('confirm-text-cancel') || 'Cancel',
  //         class: 'btn btn-secondary',
  //         handler: function(modal) {
  //           $.destroyModal(modal);
  //           eval(me.data('confirm-no'));
  //         }
  //       }
  //     ]
  //   })
  // });

  // Global
  $(function() {
    let sidebar_nicescroll_opts = {
      cursoropacitymin: 0,
      cursoropacitymax: .8,
      zindex: 892
    }, now_layout_class = null;

    var sidebar_sticky = function() {
      if($("body").hasClass('layout-2')) {
        $("body.layout-2 #sidebar-wrapper").stick_in_parent({
          parent: $('body')
        });
        $("body.layout-2 #sidebar-wrapper").stick_in_parent({recalc_every: 1});
      }
    }
    sidebar_sticky();

    var sidebar_nicescroll;
    var update_sidebar_nicescroll = function() {
      let a = setInterval(function() {
        if(sidebar_nicescroll != null)
          sidebar_nicescroll.resize();
      }, 10);

      setTimeout(function() {
        clearInterval(a);
      }, 600);
    }

    var sidebar_dropdown = function() {
      if($(".main-sidebar").length) {
        $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
        sidebar_nicescroll = $(".main-sidebar").getNiceScroll();

        $(".main-sidebar .sidebar-menu li a.has-dropdown").off('click').on('click', function() {
          var me = $(this);

          $('.main-sidebar .sidebar-menu li.active > .dropdown-menu').slideUp(500, function() {
            update_sidebar_nicescroll();
            return false;
          });
          $('.main-sidebar .sidebar-menu li.active').removeClass('active');

          if(me.parent().hasClass("active")) {
            me.parent().removeClass('active');

            me.parent().find('> .dropdown-menu').slideUp(500, function() {
              update_sidebar_nicescroll();
              return false;
            });
          }else{
            me.parent().addClass('active');

            me.parent().find('> .dropdown-menu').slideDown(500, function() {
              update_sidebar_nicescroll();
              return false;
            });
          }

          return false;
        });

        $('.main-sidebar .sidebar-menu li.active > .dropdown-menu').slideDown(500, function() {
          update_sidebar_nicescroll();
          return false;
        });
      }
    }
    sidebar_dropdown();

    if($("#top-5-scroll").length) {
      $("#top-5-scroll").css({
        height: 315
      }).niceScroll();
    }

    $(".main-content").css({
      minHeight: $(window).outerHeight() - 108
    })

    $(".nav-collapse-toggle").click(function() {
      $(this).parent().find('.navbar-nav').toggleClass('show');
      return false;
    });

    $(document).on('click', function(e) {
      $(".nav-collapse .navbar-nav").removeClass('show');
    });

    var toggle_sidebar_mini = function(mini) {
      let body = $('body');

      if(!mini) {
        body.removeClass('sidebar-mini');
        $(".main-sidebar").css({
          overflow: 'hidden'
        });
        setTimeout(function() {
          $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
          sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
        }, 500);
        $(".main-sidebar .sidebar-menu > li > ul .dropdown-title").remove();
        $(".main-sidebar .sidebar-menu > li > a").removeAttr('data-toggle');
        $(".main-sidebar .sidebar-menu > li > a").removeAttr('data-original-title');
        $(".main-sidebar .sidebar-menu > li > a").removeAttr('title');
      }else{
        body.addClass('sidebar-mini');
        body.removeClass('sidebar-show');
        sidebar_nicescroll.remove();
        sidebar_nicescroll = null;
        $(".main-sidebar .sidebar-menu > li").each(function() {
          let me = $(this);

          if(me.find('> .dropdown-menu').length) {
            me.find('> .dropdown-menu').hide();
            me.find('> .dropdown-menu').prepend('<li class="dropdown-title pt-3">'+ me.find('> a').text() +'</li>');
          }else{
            me.find('> a').attr('data-toggle', 'tooltip');
            me.find('> a').attr('data-original-title', me.find('> a').text());
            $("[data-toggle='tooltip']").tooltip({
              placement: 'right'
            });
          }
        });
      }
    }

    $("[data-toggle='sidebar']").click(function() {
      var body = $("body"),
        w = $(window);

      if(w.outerWidth() <= 1024) {
        body.removeClass('search-show search-gone');
        if(body.hasClass('sidebar-gone')) {
          body.removeClass('sidebar-gone');
          body.addClass('sidebar-show');
        }else{
          body.addClass('sidebar-gone');
          body.removeClass('sidebar-show');
        }

        update_sidebar_nicescroll();
      }else{
        body.removeClass('search-show search-gone');
        if(body.hasClass('sidebar-mini')) {
          toggle_sidebar_mini(false);
        }else{
          toggle_sidebar_mini(true);
        }
      }

      return false;
    });

    var toggleLayout = function() {
      var w = $(window),
        layout_class = $('body').attr('class') || '',
        layout_classes = (layout_class.trim().length > 0 ? layout_class.split(' ') : '');

      if(layout_classes.length > 0) {
        layout_classes.forEach(function(item) {
          if(item.indexOf('layout-') != -1) {
            now_layout_class = item;
          }
        });
      }

      if(w.outerWidth() <= 1024) {
        if($('body').hasClass('sidebar-mini')) {
          toggle_sidebar_mini(false);
          $('.main-sidebar').niceScroll(sidebar_nicescroll_opts);
          sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
        }

        $("body").addClass("sidebar-gone");
        $("body").removeClass("layout-2 layout-3 sidebar-mini sidebar-show");
        $("body").off('click touchend').on('click touchend', function(e) {
          if($(e.target).hasClass('sidebar-show') || $(e.target).hasClass('search-show')) {
            $("body").removeClass("sidebar-show");
            $("body").addClass("sidebar-gone");
            $("body").removeClass("search-show");

            update_sidebar_nicescroll();
          }
        });

        update_sidebar_nicescroll();

        if(now_layout_class == 'layout-3') {
          let nav_second_classes = $(".navbar-secondary").attr('class'),
            nav_second = $(".navbar-secondary");

          nav_second.attr('data-nav-classes', nav_second_classes);
          nav_second.removeAttr('class');
          nav_second.addClass('main-sidebar');

          let main_sidebar = $(".main-sidebar");
          main_sidebar.find('.container').addClass('sidebar-wrapper').removeClass('container');
          main_sidebar.find('.navbar-nav').addClass('sidebar-menu').removeClass('navbar-nav');
          main_sidebar.find('.sidebar-menu .nav-item.dropdown.show a').click();
          main_sidebar.find('.sidebar-brand').remove();
          main_sidebar.find('.sidebar-menu').before($('<div>', {
            class: 'sidebar-brand'
          }).append(
            $('<a>', {
              href: $('.navbar-brand').attr('href'),
            }).html($('.navbar-brand').html())
          ));
          setTimeout(function() {
            sidebar_nicescroll = main_sidebar.niceScroll(sidebar_nicescroll_opts);
            sidebar_nicescroll = main_sidebar.getNiceScroll();
          }, 700);

          sidebar_dropdown();
          $(".main-wrapper").removeClass("container");
        }
      }else{
        $("body").removeClass("sidebar-gone sidebar-show");
        if(now_layout_class)
          $("body").addClass(now_layout_class);

        let nav_second_classes = $(".main-sidebar").attr('data-nav-classes'),
          nav_second = $(".main-sidebar");

        if(now_layout_class == 'layout-3' && nav_second.hasClass('main-sidebar')) {
          nav_second.find(".sidebar-menu li a.has-dropdown").off('click');
          nav_second.find('.sidebar-brand').remove();
          nav_second.removeAttr('class');
          nav_second.addClass(nav_second_classes);

          let main_sidebar = $(".navbar-secondary");
          main_sidebar.find('.sidebar-wrapper').addClass('container').removeClass('sidebar-wrapper');
          main_sidebar.find('.sidebar-menu').addClass('navbar-nav').removeClass('sidebar-menu');
          main_sidebar.find('.dropdown-menu').hide();
          main_sidebar.removeAttr('style');
          main_sidebar.removeAttr('tabindex');
          main_sidebar.removeAttr('data-nav-classes');
          $(".main-wrapper").addClass("container");
          // if(sidebar_nicescroll != null)
          //   sidebar_nicescroll.remove();
        }else if(now_layout_class == 'layout-2') {
          $("body").addClass("layout-2");
        }else{
          update_sidebar_nicescroll();
        }
      }
    }
    toggleLayout();
    $(window).resize(toggleLayout);

    $("[data-toggle='search']").click(function(e) {
      // stop a click event
      e.preventDefault();
      e.stopPropagation();
      var body = $("body");

      if(body.hasClass('search-gone')) {
        body.addClass('search-gone');
        body.removeClass('search-show');
      }else{
        body.removeClass('search-gone');
        body.addClass('search-show');
      }
    });

    // tooltip
    $("[data-toggle='tooltip']").tooltip();

    // popover
    $('[data-toggle="popover"]').popover({
      container: 'body', html: true
    });

    // Select2
    if(jQuery().select2) {
      let select2_elements= $('.select2');
      if (select2_elements.length > 1) {
        select2_elements.each(function() {
          $(this).select2(
            {
              width: "100%"
            }
          );
        });
      } else {        
        $(".select2").select2(
            {
              width: "100%",
              placeholder: {
                text: 'Valitse...'
              }
            }
        );
      }
    }

    // $(".select2").each(function() {
    //   $(this).select2().data('select2').$selection.addClass("select2-normal");
    // });

    // Dynamic option creation
    $(".select2-tag-tokenize").select2({
      tags: true,
      tokenSeparators: [',']
    })




    // Selectric
    if(jQuery().selectric) {
      $(".selectric").selectric({
        disableOnMobile: false,
        nativeOnMobile: false
      });
    }

    $(".notification-toggle").dropdown();
    $(".notification-toggle").parent().on('shown.bs.dropdown', function() {
      $(".dropdown-list-icons").niceScroll({
        cursoropacitymin: .3,
        cursoropacitymax: .8,
        cursorwidth: 7
      });
    });

    $(".message-toggle").dropdown();
    $(".message-toggle").parent().on('shown.bs.dropdown', function() {
      $(".dropdown-list-message").niceScroll({
        cursoropacitymin: .3,
        cursoropacitymax: .8,
        cursorwidth: 7
      });
    });

    if($(".chat-content").length) {
      $(".chat-content").niceScroll({
          cursoropacitymin: .3,
          cursoropacitymax: .8,
      });
      $('.chat-content').getNiceScroll(0).doScrollTop($('.chat-content').height());
    }

    if(jQuery().summernote) {
      // $(".summernote").summernote({
      //    dialogsInBody: true,
      //   minHeight: 250,
      // });
      $(".summernote-simplexx").summernote({
        dialogsInBody: true,
        minHeight: 150,
        toolbar: [
          ['style', ['bold', 'italic', 'clear']],
          ['para', ['ul', 'ol']],
        ]
      });
      $(".summernote-simple-knowledge").summernote({
        dialogsInBody: true,
        minHeight: 150,
        toolbar: [
          ['style', ['bold', 'italic', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'picture']],
          ['view', ['codeview']],
        ]
      });
    }

    if(window.CodeMirror) {
      $(".codeeditor").each(function() {
        let editor = CodeMirror.fromTextArea(this, {
          lineNumbers: true,
          theme: "duotone-dark",
          mode: 'javascript',
          height: 200
        });
        editor.setSize("100%", 200);
      });
    }

    // Follow function
    $('.follow-btn, .following-btn').each(function() {
      var me = $(this),
          follow_text = 'Follow',
          unfollow_text = 'Following';

      me.click(function() {
        if(me.hasClass('following-btn')) {
          me.removeClass('btn-danger');
          me.removeClass('following-btn');
          me.addClass('btn-primary');
          me.html(follow_text);

          eval(me.data('unfollow-action'));
        }else{
          me.removeClass('btn-primary');
          me.addClass('btn-danger');
          me.addClass('following-btn');
          me.html(unfollow_text);

          eval(me.data('follow-action'));
        }
        return false;
      });
    });

    // Dismiss function
    $("[data-dismiss]").each(function() {
      var me = $(this),
          target = me.data('dismiss');

      me.click(function() {
        $(target).fadeOut(function() {
          $(target).remove();
        });
        return false;
      });
    });

    // Collapsable
    $("[data-collapse]").each(function() {
      var me = $(this),
          target = me.data('collapse');

      me.click(function() {
        $(target).collapse('toggle');
        $(target).on('shown.bs.collapse', function(e) {
          e.stopPropagation();
          me.html('<i class="fas fa-minus"></i>');
        });
        $(target).on('hidden.bs.collapse', function(e) {
          e.stopPropagation();
          me.html('<i class="fas fa-plus"></i>');
        });
        return false;
      });
    });

    // Gallery
    $(".gallery .gallery-item").each(function() {
      var me = $(this);

      me.attr('href', me.data('image'));
      me.attr('title', me.data('title'));
      if(me.parent().hasClass('gallery-fw')) {
        me.css({
          height: me.parent().data('item-height'),
        });
        me.find('div').css({
          lineHeight: me.parent().data('item-height') + 'px'
        });
      }
      me.css({
        backgroundImage: 'url("'+ me.data('image') +'")'
      });
    });
    if(jQuery().Chocolat) {
      $(".gallery").Chocolat({
        className: 'gallery',
        imageSelector: '.gallery-item',
      });
    }

    // Background
    $("[data-background]").each(function() {
      var me = $(this);
      me.css({
        backgroundImage: 'url(' + me.data('background') + ')'
      });
    });

    // Custom Tab
    $("[data-tab]").each(function() {
      var me = $(this);

      me.click(function() {
        if(!me.hasClass('active')) {
          var tab_group = $('[data-tab-group="' + me.data('tab') + '"]'),
              tab_group_active = $('[data-tab-group="' + me.data('tab') + '"].active'),
              target = $(me.attr('href')),
              links = $('[data-tab="'+me.data('tab') +'"]');

          links.removeClass('active');
          me.addClass('active');
          target.addClass('active');
          tab_group_active.removeClass('active');
        }
        return false;
      });
    });

    // Bootstrap 4 Validation
    $(".needs-validation").submit(function() {
      var form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass('was-validated');
    });

    // alert dismissible
    $(".alert-dismissible").each(function() {
      var me = $(this);

      me.find('.close').click(function() {
        me.alert('close');
      });
    });

    if($('.main-navbar').length) {
    }

    // Image cropper
    $('[data-crop-image]').each(function(e) {
      $(this).css({
        overflow: 'hidden',
        position: 'relative',
        height: $(this).data('crop-image')
      });
    });

    // Slide Toggle
    $('[data-toggle-slide]').click(function() {
      let target = $(this).data('toggle-slide');

      $(target).slideToggle();
      return false;
    });

    // Dismiss modal
    $("[data-dismiss=modal]").click(function() {
      $(this).closest('.modal').modal('hide');

      return false;
    });

    // Width attribute
    $('[data-width]').each(function() {
      $(this).css({
        width: $(this).data('width')
      });
    });

    // Height attribute
    $('[data-height]').each(function() {
      $(this).css({
        height: $(this).data('height')
      });
    });

    // Chocolat
    if($('.chocolat-parent').length && jQuery().Chocolat) {
      $('.chocolat-parent').Chocolat();
    }

    // Sortable card
    if($('.sortable-card').length && jQuery().sortable) {
      $('.sortable-card').sortable({
        handle: '.card-header',
        opacity: .8,
        tolerance: 'pointer'
      });
    }

    // Sortable table
    $("#sortable-table tbody").sortable({
      handle: '.sort-handler'
    });


    // Daterangepicker
    if(jQuery().daterangepicker) {
      if($(".datepicker").length) {
        $('.datepicker').daterangepicker({
          locale: {format: 'YYYY-MM-DD'},
          singleDatePicker: true,
        });
      }
      if($(".datetimepicker").length) {
        $('.datetimepicker').daterangepicker({
          locale: {format: 'YYYY-MM-DD hh:mm'},
          singleDatePicker: true,
          timePicker: true,
          timePicker24Hour: true,
        });
      }
      if($(".daterange").length) {
        $('.daterange').daterangepicker({
          locale: {format: 'YYYY-MM-DD'},
          drops: 'down',
          opens: 'right'
        });
      }
    }

    // Timepicker
    if(jQuery().timepicker && $(".timepicker").length) {
      $(".timepicker").timepicker({
        icons: {
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down'
        }
      });
    }
  });

  if($('#form_nettix_ads').length > 0) {
    get_user_list();
    get_option_make();
    get_option_model();
    get_option_model_type();
    get_option_body_type();
    get_town();
  }

  function get_user_list() {
    let url = "/api/v1/nettix/get_user_list?id=" + $('#nettix_ad_user_id').attr('data')
    let select_id = '#nettix_ad_user_id'
    change_options(url, select_id);
  }

  $("#nettix_ad_vehicle_type").on("change", function () {
    get_option_make();
    get_option_body_type()
  });

  function get_option_make() {
    let url = "/api/v1/nettix/get_option_make?id=" + $('#nettix_ad_make').attr('data') + "&vehicle_type=" + $('#nettix_ad_vehicle_type').val()
    let select_id = '#nettix_ad_make'
    change_options(url, select_id);
  }

  $("#nettix_ad_make").on("change", function () {
    get_option_model();
    $("#nettix_ad_make_name").val($("#nettix_ad_make option:selected").text())
    // alert($("#nettix_ad_make_name").val())
  });

  function get_option_model() {
    let url = "/api/v1/nettix/get_option_model?ad_id=" + $('#nettix_ad_id').val() + "&make_id=" + $('#nettix_ad_make').val() + "&this_id=" + $('#nettix_ad_model').attr('data')
    let select_id = '#nettix_ad_model'
    change_options(url, select_id);
  }

  $("#nettix_ad_model").on("change", function () {
    get_option_model_type();
    $("#nettix_ad_car_model_name").val($("#nettix_ad_model option:selected").text())
    // alert($("#nettix_ad_car_model_name").val())
  });

  $("#nettix_ad_year").on("change", function () {
    get_option_model_type();
  });


  function get_option_model_type() {
    let url = "/api/v1/nettix/get_option_model_type?ad_id=" + $('#nettix_ad_id').val() + "&model_id=" + $('#nettix_ad_model').val() + "&year=" + $('#nettix_ad_year').val()
    let select_id = '#nettix_ad_model_type'
    change_options(url, select_id);
  }


  function get_option_body_type() {
    let url = "/api/v1/nettix/get_option_body_type?ad_id=" + $('#nettix_ad_id').val() + "&vehicle_type=" + $('#nettix_ad_vehicle_type').val()
    let select_id = '#nettix_ad_body_type'
    change_options(url, select_id);
  }

  $("#nettix_ad_region").on("change", function () {
    get_town();
  });

  function get_town() {
    if( $('#nettix_ad_id').val()  &&  $('#nettix_ad_region').val()) {
      let url = "/api/v1/nettix/get_town?ad_id=" + $('#nettix_ad_id').val() + "&region=" + $('#nettix_ad_region').val()
      let select_id = '#nettix_ad_town'
      change_options(url, select_id);
    }
  }


  // get_option_color()
  // function get_option_color() {
  //   let url = "/api/v1/nettix/get_option_color?ad_id=" + $('#nettix_ad_id').val()
  //   let select_id = '#nettix_ad_color'
  //   change_options(url, select_id);
  // }

  function change_options(url, select_id){
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json', // added data type
      success: function (res) {
        // console.log(res);

        // let valid_item = false
        // for (const item of res) {
        //   console.log(item);
        //   if (item.hasOwnProperty('selected')) {
        //     console.log('selected');
        //     valid_item = true
        //   }
        // }
        // let add_class = "select2-normal"
        // if (valid_item){
        //   console.log("Add class to ---" + select_id)
        //   add_class = "select2-valid"
        // }

        res.unshift({"id": 0, "text": "Please Select" })

        $(select_id).empty();
        $(select_id).select2({
          placeholder: {
            id: 0, // the value of the option
            text: 'Please Select'
          },
          data: res,
          width: "100%"
        });
        // }).data('select2').$selection.addClass(add_class);
      }
    });
  }

  // $("#nettix_ad_price").focusout(function() {
  //   let us_price = $(this).val().replace(',','.').replace(/\s/g, '');
  //   let eu_price  =  parseFloat(us_price).toLocaleString('fi',{minimumFractionDigits: 0, maximumFractionDigits: 0});
  //   $(this).val(eu_price)
  // });
  //
  // $(".modalBtnAddImageClick").on("click", function (event) {
  //   alert('Start modal');
  //   // $('.modal').modal('hide');
  //   // $("#modalAddImage").modal("show");
  //   // $("#modalAddImage").modal('toggle');
  //
  // });
  $("#freeDriveModal").on("shown.bs.modal", function() {
    $('.modal-backdrop').hide();
  });
  $("#extraNotesModal").on("shown.bs.modal", function() {
    $('.modal-backdrop').hide();
  });
  $(".modal_inspection_image").on("shown.bs.modal", function() {
    $('.modal-backdrop').hide();
  });
  $("#modalAddImage").on("shown.bs.modal", function() {
    $('.modal-backdrop').hide();
  });

  $("#modalEditSelected").on("shown.bs.modal", function(event) {
    var button = $(event.relatedTarget); // Button that triggered the modal
    var dataTypeName = button.data('type-name'); // Extract info from data-* attributes
    console.log("dataTypeName", dataTypeName);
    if (dataTypeName === "paper") {
      $(".inputs").hide()
      $(".paper_inputs").show()
    } else if (dataTypeName === "arrive") {
      $(".inputs").hide()
      $(".arrive_inputs").show()
    } else if (dataTypeName === "inspect") {
      $(".inputs").hide()
      $(".inspect_inputs").show()
    } else if (dataTypeName === "sticker") {
      $(".inputs").hide()
      $(".sticker_inputs").show()
    } else if (dataTypeName === "fix") {
      $(".inputs").hide()
      $(".fix_inputs").show()
    } else {
      $(".inputs").show()
      $(".fix_inputs").hide()
    }
    $(".inputs input:checkbox").prop('checked', false);
    $(".inputs input[type=date]").val("");

  });

  // Every time a modal is shown, if it has an autofocus element, focus on it.
  $('.modal').on('shown.bs.modal', function(event) {
    $('.modal-backdrop').hide();
    $(this).find('[autofocus]').focus();
  });

  $(".modalBtnClick").on("click", function (event) {
    $('#myModal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    var button = $(this) // Button that triggered the modal
    var accessory_group = button.data('group') // Extract info from data-* attributes
    $(".accessory-group-name").each(function() {
      if ($(this).data('group') == accessory_group) {
        $(this).show();
      }else{
        $(this).hide();
      }
    });
  });

  $("#reservationSellcarModal").on("shown.bs.modal", function() {
    $('.modal-backdrop').hide();
  });

  $('#save_modal').on('click', function() {
    let array = [];
    // alert($('#nettix_ad_accessories').val())
    let nettix_ad_accessories = $('#nettix_ad_accessories').val().replace(/[\[\]']+/g,'').split(", ");
    $(".accessory-item:checkbox:checked").each(function() {
      array.push($(this).val());
    });
    $(".accessory-input-button").each(function() {
      if (array.includes($(this).data('id').toString())) {
        $(this).removeAttr('hidden');
        $(this).show();
        nettix_ad_accessories = add_accessory($(this).data('id').toString(), nettix_ad_accessories)
      }else{
        $(this).hide();
        nettix_ad_accessories = remove_accessory($(this).data('id').toString(), nettix_ad_accessories)
      }
    });
    $('#nettix_ad_accessories').val("[" + nettix_ad_accessories.join(", ") + "]");
    // alert($('#nettix_ad_accessories').val())
    $('.modal').modal('hide');
  });

  // check_select2();

  // $(".select2") .change(function () {
  //   check_select2();
  // }); nettix_ad_make


  function remove_accessory(text, array){
    const index_acc = array.indexOf(text);
    if (index_acc > -1) {
      array.splice(index_acc, 1);
    }
    return array
  }

  function add_accessory(text, array){
    array = remove_accessory(text, array);
    array.push(text);
    return array;
  }

  function check_select2(){
    $('.select2-selection--single').each(function() {
      alert($(this).getAttribute("aria-labelledby"))
      $(this).select2().data('select2').$selection.addClass("select2-normal");
    });

    // $(".select2-valid").each(function() {
    //   $(this).select2().data('select2').$selection.removeClass("select2-invalid");
    //   $(this).select2().data('select2').$selection.removeClass("select2-normal");
    //   $(this).select2().data('select2').$selection.addClass("select2-valid");
    // });
    //
    // $(".select2-invalid").each(function() {
    //   $(this).select2().data('select2').$selection.removeClass("select2-valid");
    //   $(this).select2().data('select2').$selection.removeClass("select2-normal");
    //   $(this).select2().data('select2').$selection.addClass("select2-invalid");
    // });
  }

  $('.imagecheck-input:checkbox').click(function(event) {
    const limit = 15
    if (this.checked && $('.imagecheck-input:checkbox:checked').length > limit) {
      this.checked = false;
      alert('NettiX not allowed to have more than ' + limit.toString() + ' pictures');
    }
  });

  $(".image_partial").on("click","#upload_image", function(){

    if ($('#file_image').val()) {
      $(this).prop('disabled', true);
      $(this).html('Loading please wait...');
      var file_data = $('#file_image').prop('files')[0];
      var form_data = new FormData();
      form_data.append('file', file_data);
      const nettix_id = $(this).attr('data-nettix-ad-id');
      $.ajax({
        url: '/nettix_ads/image_upload/'+ nettix_id, // point to server-side
        dataType: 'text',  // what to expect back from server
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function(response){
          alert(response)
          $.get("/nettix_ads/image_reload/"+ nettix_id, {}, null, "script");
        }
      });
    } else {
      alert('Please select picture!!');
    }

  });

  $(".image_partial").on("click",".imagecheck-delete", function(){
    if (confirm("Delete picture?")) {

      const nettix_id = $(this).attr('data-nettix-ad-id');
      const picture_id = $(this).attr('data-picture-id');

      $.ajax({
        url: '/nettix_ads/image_delete/'+ nettix_id + '/' +  picture_id, // point to server-side
        type: 'GET',
        dataType: 'text',
        success: function(response){
          alert(response)
          $.get("/nettix_ads/image_reload/"+ nettix_id, {}, null, "script");
        }
      });
    }
  });

  $(".image_partial").on("click",".imagecheck-copy", function(){
    if (confirm("Create Copy of this picture to remove background?")) {
      const nettix_id = $(this).attr('data-nettix-ad-id');
      const picture_id = $(this).attr('data-picture-id');

      $.ajax({
        url: '/nettix_ads/image_cloudinary/'+ nettix_id + '/' +  picture_id, // point to server-side
        type: 'GET',
        dataType: 'text',
        success: function(response){
          alert(response)

          setTimeout(function () {
            // this code will execute after 5 second
            $.get("/nettix_ads/image_reload/"+ nettix_id, {}, null, "script");
          }, 5000);
        }
      });
    }
  });

  $(".image_partial").on("click",".imagecheck-view", function(){
    window.open($(this).attr('data-link'))
  });

  $(document).on("blur",".payment_finance_mark", function(){
    const mark = $(this).val();
    $.get("/autolle/payments/edit_mark",  {id: $(this).attr('data-id'), mark: mark}, function (response) {
      console.log("edit_mark", response);
      if (response === 'success') {
        iziToast.success({
          message: `${mark} is added`,
          title: "Success", position: "topRight"
        });
      } else {
        iziToast.error({
          message: "Something went wrong, please try again later.",
          title: "Error", position: "topRight"
        });
      }
    });
  });

  $(document).on("change",".customer_selected_service", function(e){
    // disable this checkbox
    if ($(this).prop('disabled')) {
      console.log("disabled")
      return;
    }
    $(this).prop('disabled', true);

    let checkbox = $(this);
    const customer_id = $(this).attr('data-customer-id');
    const service_id = $(this).attr('data-service-id');
    const contract_number = $(this).attr('data-contract-number');
    const sales_plate = $(this).attr('data-sales-plate');
    const exchange_plate = $(this).attr('data-exchange-plate');
    let action = 'POST';
    let url = `/customers/${customer_id}/selected_services`;
    if (e.target.type === 'checkbox') {
      if (!this.checked) {
        // TODO: delete selected service ??
        action = 'DELETE';
        url = ``;
        checkbox.prop('disabled', false);
        return;
      }
    }

    const service_params = {
      selected_service: { available_service_id: service_id, action: action },
      contract_number: contract_number,
      sales_plate: sales_plate,
      exchange_plate: exchange_plate,
      action: action,
    };

    $.ajax({
      url: url,
      type: action,
      dataType: 'text',
      data: service_params,
    }).always(function (response) {
      console.log('selected_services response:', response);
    });


    setTimeout(function () {
      // this code will execute after 1.5 second
      $('#customer_selected_services').load(`/customers/${customer_id}/reload_selected_services?contract_number=${contract_number}`);
      console.log("AFTER reload customer_selected_services");
      iziToast.success({
        message: `Palvelu päivitetty`,
        title: "Success", position: "topCenter"
      });
      checkbox.prop('disabled', false);
    }, 1500);
  });


  $(document).on('change', '#greyhen_make', function () {
    console.log(".greyhen-form #make", $('#greyhen_make').val());
    change_model()
  });
  $(document).on('change', '#greyhen_model', function () {
    change_variant()
  });

  $(document).on('change', '#tax_make', function () {
    change_car_tax_model()
  });

  function change_car_tax_model() {
    let make = $('#tax_make').val();
    $.ajax({
      url: '/car_tax_rates_models.json?make='+ make, // point to server-side
      type: 'GET',
      dataType: 'json',
      success: function(response){
        console.log("change_model tax response", response);
        let result = response.models
        console.log("models result", result);
        if (result) {
          result.unshift('all')
          $('#tax_model').empty();
          $('#tax_model').select2({
            data: result,
            width: 'element'
          });
        }
      }
    });
  }

  function change_model() {
    let make = $('#greyhen_make').val();
    $.ajax({
      url: '/greyhens.json?greyhen_make='+ make, // point to server-side
      type: 'GET',
      dataType: 'json',
      success: function(response){
        console.log("change_model greyhen response", response);
        let result = response.models
        console.log("models result", result);
        if (result) {
          result.unshift('Malli')
          $('#greyhen_model').empty();
          $('#greyhen_model').select2({
            data: result,
            width: "100%"
          });
        }
        let variants = response.variants
        if (variants) {
          variants.unshift('Variantti')
          $('#greyhen_variant').empty();
          $('#greyhen_variant').select2({
            data: variants,
            width: "100%"
          });
        }
      }
    });
  }

  function change_variant() {
    let make = $('#greyhen_make').val();
    let model = $('#greyhen_model').val();

    $.ajax({
      url: '/greyhens.json?greyhen_make='+ make + '&greyhen_model=' + model, // point to server-side
      type: 'GET',
      dataType: 'json',
      success: function(response){
        console.log("change_variant greyhen response", response);
        let result = response.variants
        if (result) {
          result.unshift('Variantti')
          $('#greyhen_variant').empty();
          $('#greyhen_variant').select2({
            data: result,
            width: "100%"
          });
        }
      }
    });
  }

  let default_purchase_form_office = localStorage.getItem('default_purchase_form_office')
  if (default_purchase_form_office) {
    if ($('#purchase_form_office').val() === '') {
      $('#purchase_form_office').val(default_purchase_form_office)
    }
  }
  $(document).on('change', '#purchase_form_office', function () {
    localStorage.setItem('default_purchase_form_office', $('#purchase_form_office').val());
  })

  let double_scroll_tables= $('.double-scroll-table');
  if (double_scroll_tables.length > 0) {
    double_scroll_tables.each(function() {
      let me = $(this);
      if (me.doubleScroll) {
        me.doubleScroll();
        console.log("doubleScroll found and loaded ")
      } else {
        console.log("doubleScroll lib not found");
      }
    });
  }

  $(document).on('click', '.gallery-item', function() {
    const imageUrl = this.getAttribute('data-image');
    const imageTitle = this.getAttribute('data-title');
    console.log("image click xx", imageUrl, imageTitle);
    if (imageUrl) {
      window.open(imageUrl, '_blank_img', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800');
    }
  });
});
