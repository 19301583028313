import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Brand data controller connected")
    let p7_list = document.querySelectorAll(`input[name^="brand_order_agreement"][type=number][data-prepend="7"]`)
    p7_list.forEach((p7) => {
      p7.style.backgroundColor = "#e9ecef"
    });
    let p6 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="6"]`)
    let p10 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="10"]`)
    let p11 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="11"]`)
    let p13 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="13"]`)
    p6.style.backgroundColor = "#e9ecef"
    p10.style.backgroundColor = "#e9ecef"
    p11.style.backgroundColor = "#e9ecef"
    p13.style.backgroundColor = "#e9ecef"

  }
  updatePaymentData(e) {
    let element = e.target;
    if(element){

      let r_purchase_price = document.getElementById("brand_order_agreement_replacement_vehicle_purchase_price")
      let r_residual_debt = document.getElementById("brand_order_agreement_replacement_vehicle_residual_debt")

      let p7_list = document.querySelectorAll(`input[name^="brand_order_agreement"][type=number][data-prepend="7"]`)
      p7_list.forEach((p7) => {
        p7.value = this.get_value(r_purchase_price) - this.get_value(r_residual_debt)
      });

      let p1 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="1"]`)
      let p2 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="2"]`)
      let p3 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="3"]`)
      let p4 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="4"]`)
      let p5 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="5"]`)
      let p6 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="6"]`)
      let p7 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="7"]`)
      let p8 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="8"]`)
      let p9 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="9"]`)
      let p10 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="10"]`)
      let p11 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="11"]`)
      let p12 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="12"]`)
      let p13 = document.querySelector(`input[name^="brand_order_agreement"][type=number][data-prepend="13"]`)

      p6.value = this.get_value(p1) + this.get_value(p2) + this.get_value(p3) + this.get_value(p4) + this.get_value(p5)
      p10.value = this.get_value(p7) + this.get_value(p8) + this.get_value(p9)
      p11.value = this.get_value(p6) - this.get_value(p10)
      p13.value = this.get_value(p11) + this.get_value(p12)

    }
  }

  get_value(element) {
     if (element.value && !isNaN(element.value))
        return parseInt(element.value)
     else
        return 0
  }
}
