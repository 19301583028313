import { Controller } from "stimulus"

export default class extends Controller {
    toggleVisibility(event) {
        const target = event.target
        const expanded = target.getAttribute("aria-expanded")
        const targetElements = target.querySelectorAll('.collapse-indicator')
        Array.from(targetElements).forEach(element => {
            element.classList.toggle('d-none')
        })
    }
}
