import { Controller } from "stimulus";

export default class extends Controller {
	pickColor(event) {
		const color = event.currentTarget.dataset.color;
		console.log("pick color",color);
		// id  knowledge_library_theme_color
		const colorField = document.getElementById("knowledge_library_theme_color");
		if (colorField) {
			colorField.value = color;
		}
	}
}