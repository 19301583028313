import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		console.log('connected  to Organization Controller')
	}

	changeWorkArea(e) {
		const targetDivId = e.target.id
		// 	/user_organizations/holiday
		if (targetDivId === 'own') {
			window.location.href = '/user_organizations/own'
		} else {
			$("#work_area").load(`/user_organizations/${targetDivId}`)
		}
	}

	loadCalendar(e) {
		const targetDivId = e.target.id
		$("#work_area").load(`/user_organizations/${targetDivId}`)
	}
}